import React from "react";
import { Form, Input, Button, Popconfirm, message } from "antd";

function TourContactListForm(options: {
    form: any,
    config: any,
    hasTravelAgent: any,
    isFirstRegistration: any,
    transferGuideState: any,
    setTransferGuideState: any,
    tourStaffState: any,
    setTourStaffState: any,
}) {
    const {
        form,
        config,
        hasTravelAgent,
        isFirstRegistration,
        transferGuideState,
        setTransferGuideState,
        tourStaffState,
        setTourStaffState,
    } = options;
    const deleteGuide = (identity: string) => {
        if (identity === "tourStaff") {
            form.setFieldValue("tourStaff", {
                identity: "隨團工作人員",
                isDelete: true,
            });
            setTourStaffState("deleted");
        } else if (identity === "transferGuide") {
            form.setFieldValue("transferGuide", {
                identity: "接關導遊",
                isDelete: true,
            });
            setTransferGuideState("deleted");
        }
    };
    const cancel = (e: any) => {
        console.log(e);
        message.error("取消刪除之前填寫信息");
    };

    return (
        <Form
            form={form}
            name="tourContactList"
            layout="vertical"
            scrollToFirstError
        >
            <Form.Item style={{ width: "100%" }}>
                以下兩名負責人的其中一人須為董事／合夥人／獨資經營者，另一人須為僱員、或董事／合夥人。該兩名負責人均不得為本登記表格上所登記的接待導遊或接關導遊（如有）。如本登記表格的內地入境旅行團發生事故，已登記的負責人中的最少一人須親自到場處理。
            </Form.Item>
            <Form.Item
                name={["firstPerson", "identity"]}
                initialValue="第一負責人"
                style={{
                    display: "none",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                // name="headcount"
                name={["firstPerson", "name"]}
                label="第一名負責人姓名"
                // noStyle
                {...config}
                style={{
                    display: "inline-block",
                    // width: '50%',
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["firstPerson", "phone"]}
                label="第一名負責人的手提電話號碼"
                {...config}
                style={{
                    display: "inline-block",
                    // width: '50%',
                }}
            >
                <Input
                    // onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        form.setFieldValue(
                            "firstPerson",
                            Object.assign(form.getFieldsValue().firstPerson, {
                                phone: e.target.value.replace(/\D/g, ""),
                            })
                        );
                    }}
                />
            </Form.Item>

            <Form.Item
                name={["secondPerson", "identity"]}
                initialValue="第二負責人"
                style={{
                    display: "none",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                // name="headcount"
                name={["secondPerson", "name"]}
                label="第二名負責人姓名"
                {...config}
                style={{
                    display: "inline-block",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["secondPerson", "phone"]}
                label="第二名負責人的手提電話號碼"
                {...config}
                style={{
                    display: "inline-block",
                }}
            >
                <Input
                    // onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        form.setFieldValue(
                            "secondPerson",
                            Object.assign(form.getFieldsValue().secondPerson, {
                                phone: e.target.value.replace(/\D/g, ""),
                            })
                        );
                    }}
                />
            </Form.Item>

            {hasTravelAgent && (
                <>
                    <Form.Item
                        name={["travelAgent", "identity"]}
                        initialValue="內地入境旅行團單位"
                        style={{
                            display: "none",
                        }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        // name="headcount"
                        name={["travelAgent", "name"]}
                        label={hasTravelAgent + "名稱"}
                        // noStyle
                        {...config}
                        style={{
                            display: "inline-block",
                            // width: '50%',
                        }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={["travelAgent", "phone"]}
                        label={hasTravelAgent + "電話號碼"}
                        {...config}
                        style={{
                            display: "inline-block",
                            // width: '50%',
                        }}
                    >
                        <Input
                            // onKeyDown={handleKeyDown}
                            onChange={(e) => {
                                form.setFieldValue(
                                    "travelAgent",
                                    Object.assign(
                                        form.getFieldsValue().travelAgent,
                                        {
                                            phone: e.target.value.replace(
                                                /\D/g,
                                                ""
                                            ),
                                        }
                                    )
                                );
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name={["travelAgentPerson", "identity"]}
                        initialValue="內地入境旅行團單位負責人"
                        style={{
                            display: "none",
                        }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        // name="headcount"
                        name={["travelAgentPerson", "name"]}
                        label={hasTravelAgent + "負責人姓名"}
                        // noStyle
                        {...config}
                        style={{
                            display: "inline-block",
                            // width: '50%',
                        }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={["travelAgentPerson", "phone"]}
                        label={hasTravelAgent + "負責人電話號碼"}
                        {...config}
                        style={{
                            display: "inline-block",
                            // width: '50%',
                        }}
                    >
                        <Input
                            // onKeyDown={handleKeyDown}
                            onChange={(e) => {
                                form.setFieldValue(
                                    "travelAgentPerson",
                                    Object.assign(
                                        form.getFieldsValue().travelAgentPerson,
                                        {
                                            phone: e.target.value.replace(
                                                /\D/g,
                                                ""
                                            ),
                                        }
                                    )
                                );
                            }}
                        />
                    </Form.Item>
                </>
            )}

            <Form.Item
                name={["tourGuide", "licenseNumber"]}
                label="接待導遊牌照號碼"
                {...config}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["tourGuide", "identity"]}
                initialValue="接待導遊"
                style={{
                    display: "none",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["tourGuide", "name"]}
                label="接待導遊姓名"
                {...config}
                style={{
                    display: "inline-block",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["tourGuide", "phone"]}
                label="接待導遊的手提電話號碼"
                {...config}
                style={{
                    display: "inline-block",
                }}
            >
                <Input
                    // onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        form.setFieldValue(
                            "tourGuide",
                            Object.assign(form.getFieldsValue().tourGuide, {
                                phone: e.target.value.replace(/\D/g, ""),
                            })
                        );
                    }}
                />
            </Form.Item>

            <Form.Item
                name={["transferGuide", "licenseNumber"]}
                label="接關導遊牌照號碼（如沒有接關導遊，可不填寫）"
            >
                <Input disabled={transferGuideState === "deleted"} />
            </Form.Item>
            <Form.Item
                name={["transferGuide", "identity"]}
                initialValue="接關導遊"
                style={{
                    display: "none",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["transferGuide", "name"]}
                label="接關導遊姓名"
                style={{
                    display: "inline-block",
                }}
            >
                <Input disabled={transferGuideState === "deleted"} />
            </Form.Item>
            <Form.Item
                name={["transferGuide", "phone"]}
                label="接關導遊的手提電話號碼"
                style={{
                    display: "inline-block",
                }}
            >
                <Input
                    disabled={transferGuideState === "deleted"}
                    // onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        form.setFieldValue(
                            "transferGuide",
                            Object.assign(form.getFieldsValue().transferGuide, {
                                phone: e.target.value.replace(/\D/g, ""),
                            })
                        );
                    }}
                />
            </Form.Item>
            {!isFirstRegistration && (
                <Form.Item name={["transferGuide", "isDelete"]}>
                    <Popconfirm
                        title="刪除接關導遊信息"
                        description="確定要刪除之前填寫的接關導遊信息嗎？"
                        onConfirm={() => deleteGuide("transferGuide")}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                        disabled={
                            transferGuideState === "deleted" ||
                            transferGuideState === "empty"
                        }
                    >
                        <Button
                            danger
                            disabled={
                                transferGuideState === "deleted" ||
                                transferGuideState === "empty"
                            }
                        >
                            刪除接關導遊
                        </Button>
                    </Popconfirm>
                </Form.Item>
            )}

            <Form.Item
                name={["tourStaff", "identity"]}
                initialValue="隨團工作人員"
                style={{
                    display: "none",
                }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["tourStaff", "name"]}
                label="隨團工作人員姓名（如沒有隨團工作人員，可不填寫）"
                style={{
                    display: "inline-block",
                }}
            >
                <Input disabled={tourStaffState === "deleted"} />
            </Form.Item>
            <Form.Item
                name={["tourStaff", "phone"]}
                label="隨團工作人員的手提電話號碼"
                style={{
                    display: "inline-block",
                }}
            >
                <Input
                    disabled={tourStaffState === "deleted"}
                    // onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        form.setFieldValue(
                            "tourStaff",
                            Object.assign(form.getFieldsValue().tourStaff, {
                                phone: e.target.value.replace(/\D/g, ""),
                            })
                        );
                    }}
                />
            </Form.Item>
            {!isFirstRegistration && (
                <Form.Item name={["tourStaff", "isDelete"]}>
                    <Popconfirm
                        title="刪除隨團工作人員信息"
                        description="確定要刪除之前填寫的隨團工作人員信息嗎？"
                        onConfirm={() => deleteGuide("tourStaff")}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                        disabled={
                            tourStaffState === "deleted" ||
                            tourStaffState === "empty"
                        }
                    >
                        <Button
                            danger
                            disabled={
                                tourStaffState === "deleted" ||
                                tourStaffState === "empty"
                            }
                        >
                            刪除隨團工作人員
                        </Button>
                    </Popconfirm>
                </Form.Item>
            )}

            {/* <Form.Item>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item> */}
        </Form>
    );
}

export default TourContactListForm;
