import { Routes, Route, Outlet, Link, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Registration from "./pages/Registration";
import MyTemplates from "./pages/MyTemplates";
import MySubmissionsPage from "./pages/MySubmissions";
import EditForm from "./pages/EditForm/booking";
import Logo from './images/logo.png';
import { AuthContext } from './AuthContext';
import { useEffect, useState } from "react";
import Login from "./pages/Login";
import { Row, Col, Button } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;

export default function App() {
    const [loggedIn, setLoggedIn] = useState(() => {
        const savedLoggedIn = localStorage.getItem('loggedIn');
        return savedLoggedIn ? JSON.parse(savedLoggedIn) : false;
    });

    useEffect(() => {
        localStorage.setItem('loggedIn', JSON.stringify(loggedIn));
        if (loggedIn) {
            const refreshSession = async () => {
                try {
                    await fetch(`${API_URL}/refresh-session`, {
                        method: 'POST',
                        credentials: 'include', 
                    });
                } catch (err) {
                    console.log('Failed to refresh session');
                }
            };
        
            refreshSession();
            const intervalId = setInterval(refreshSession, 12 * 60 * 60 * 1000); // 12 hours
            return () => clearInterval(intervalId);
        }
    }, [loggedIn]);

    return (
        <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
            <Routes>
                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={loggedIn ? <Navigate to="/" /> : <Login />} />
                <Route path="/" element={!loggedIn ? <Navigate to="/login" /> : <Layout />} >
                    <Route index element={<MyTemplates />} />
                    <Route path="my-templates" element={<MyTemplates />} />
                    <Route path="my-submissions" element={<MySubmissionsPage />} />
                    <Route path="new-form" element={<EditForm />} />
                    <Route path="template/:id" element={<EditForm />} />
                    <Route path="form/:recordStr" element={<EditForm />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </AuthContext.Provider>
    );
}

async function handleLogout() {
    const reponse = await fetch(`${API_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
    });
    if (reponse.status === 200) {
        localStorage.removeItem('loggedIn');
        window.location.href = '/login';
    }
    else {
        console.log('Failed to logout');
    }
}

function Layout() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top navbar-dark navbarScroll">
                <div className="navbar-logo" style={{ marginRight: '0.5rem' }}>
                    <img src={Logo} alt="Logo" />
                </div>
                <h1>Tour Fast 團快</h1>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <Row justify="space-between" align="middle">
                        <Col>
                            <ul className="navbar-nav ms-auto">
                                {/* <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/my-templates">我的範本</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/my-submissions">提交紀錄</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleLogout}>登出</Button>
                        </Col>
                    </Row>
                </div>
            </nav>
            <hr />
            <Outlet />
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}
