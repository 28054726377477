import React, { useContext } from 'react';
import { Form, Input, Button, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { AuthContext } from '../../AuthContext';
import styles from './index.module.css';
import { login } from '../../api';
import Logo from '../../images/logo.png';

const LoginForm: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { setLoggedIn } = authContext || {};

    const onFinish = async (values: any) => {
        console.log('Received values of form: ', values);
        const response = await login(values.username, values.password);
        if (response.status === 200) {
            const result = await response.json();
            if (setLoggedIn && result.success) {
                console.log('Login success');
                setLoggedIn(true); // log the user in when the form is submitted
            }
            else {
                console.log('Login failed');
            }
        }
        else {
            console.log('Login failed');
        }
    };

    return (
        <div className={styles.container}>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <h1 style={{ textAlign: 'center' }}>Tour Fast 團快</h1>
                <img src={Logo} alt="Logo" height={300} width={300} style={{ marginBottom: '1rem' }}/>
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: '請輸入用戶名稱!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用戶名稱" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '請輸入密碼!' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="密碼"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                        登入
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginForm;