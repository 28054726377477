// const API_URL = "https://inbreg.tia.org.hk";
const API_URL = process.env.REACT_APP_API_URL;
let jwtToken: string;

export async function submitRegisteredRequest(fields: any) {
    // return new Promise((resolve, reject) => {
    //     axios({
    //         method: "post",
    //         url: API_URL + "/tbms/submitRegisteredRequest",
    //         data: {
    //             registrationEmail: fields.registrationEmail,
    //             registrationNumber: fields.registrationNumber,
    //         },
    //     })
    //         .then(function (response) {
    //             console.log("submitRegisteredRequest:", response);
    //             if (response.data.responseCode === "SUCCESS") {
    //                 jwtToken = response.data.authToken;
    //                 resolve(response.data);
    //             } else {
    //                 reject(response.data);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // });
    return new Promise(async (resolve, reject) => {
        const response = await fetch(`${API_URL}/submit-registered-request`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(fields),
        });
        const result = await response.json();
        if (result.success) {
            if (result.data.responseCode === "SUCCESS") {
                jwtToken = result.data.authToken;
                resolve(result.data);
            } else {
                reject(result.data);
            }
        }
    });
}

export async function fetchTiaData() {
    let data;
    const response = await fetch(`${API_URL}/tia-data`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    const result = await response.json();
    if (result.success) {
        data = result.data;
    }
    return data;
}

export async function submitGeneralTourInformation(fields: any) {
    const response = await fetch(API_URL + "/submit-general-tour-information", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            fields,
            authToken: jwtToken,
        }),
    });
    const result = await response.json();
    if (result.success) {
        return result;
    } else {
        throw new Error(result.message);
    }
}

/** Part II End */

function removeFalsyValues(obj: any) {
    for (let key in obj) {
        if (!obj[key]) {
            delete obj[key];
        }
    }
    return obj;
}

/** Part III */
export async function submitTourContactList(fields: any) {
    for (let contact of fields.contactList) {
        removeFalsyValues(contact);
    }

    const response = await fetch(API_URL + "/submit-tour-contact-list", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            recordId: fields.recordId,
            contactList: fields.contactList,
            authToken: jwtToken,
        }),
    });
    const result = await response.json();
    if (result.success) {
        return result;
    } else {
        throw new Error(result.message);
    }
}

/** Part III End */

export async function submitTourSchedule(fields: any) {
    const response = await fetch(API_URL + "/submit-tour-schedule", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            recordId: fields.recordId,
            scheduleList: fields.scheduleList,
            authToken: jwtToken,
        }),
    });
    const result = await response.json();
    if (result.success) {
        return result;
    } else {
        throw new Error(result.message);
    }
}
/** Part IV End */

/** Part V */
export async function submitTourAccommodation(fields: any) {
    const response = await fetch(API_URL + "/submit-tour-accommodation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            recordId: fields.recordId,
            accommodationProvider: fields.accommodationProvider,
            accommodation: fields.accommodation,
            authToken: jwtToken,
        }),
    });
    const result = await response.json();
    if (result.success) {
        return result;
    } else {
        throw new Error(result.message);
    }
}
/** Part V End */

export async function sendConfirmation(registrationNumber: string) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(API_URL + "/send-confirmation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                registrationNumber: registrationNumber,
                authToken: jwtToken,
            }),
            credentials: "include",
        });
        const result = await response.json();
        if (result.success) {
            if (result.data.responseCode === "SUCCESS") {
                resolve(result.data);
            } else {
                reject(result.data);
            }
        }
    });
}

export async function getTourInformationByRecordId(fields: any) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(
            API_URL + "/get-tour-information-by-record-id",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    recordId: fields.recordId,
                    referenceNumber: fields.referenceNumber,
                    authToken: jwtToken,
                }),
                credentials: "include",
            }
        );
        const result = await response.json();
        if (result.success) {
            if (result.data.responseCode === "SUCCESS") {
                resolve(result.data);
            } else {
                reject(result.data);
            }
        }
    });
}

export async function getTourAccommodationFileStream(fileName: any) {
    const response = await fetch(
        API_URL + "/get-tour-accommodation-file-stream",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                fileName: fileName,
                authToken: jwtToken,
            }),
            credentials: "include",
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const buffer = await response.arrayBuffer();
    return buffer;
    //   return axios({
    //     method: 'post',
    //     url: API_URL + '/tbms/getTourAccommodationFileStream',
    //     headers: {
    //       Authorization: `Bearer ${jwtToken}`
    //     },
    //     responseType: 'arraybuffer',
    //     data: {
    //       fileName: fileName
    //     }
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //     return response
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
}

async function hashPassword(password: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(password);
    const salt = encoder.encode("salt"); // use the same salt as in the server side function
    const key = await window.crypto.subtle.importKey(
        "raw",
        data,
        { name: "PBKDF2" },
        false,
        ["deriveBits"]
    );
    const hash = await window.crypto.subtle.deriveBits(
        { name: "PBKDF2", salt: salt, iterations: 1000, hash: "SHA-256" },
        key,
        256
    );
    return Array.from(new Uint8Array(hash))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
}

export async function register(
    username: string,
    email: string,
    password: string
) {
    const hashedPassword = await hashPassword(password);
    const response = await fetch(`${API_URL}/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password: hashedPassword }),
        credentials: "include",
    });
    return response;
}

export async function login(username: string, password: string) {
    const hashedPassword = await hashPassword(password);
    const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password: hashedPassword }),
        credentials: "include",
    });
    return response;
}

export async function fetchMyTemplates() {
    const response = await fetch(`${API_URL}/my-templates`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return response;
    // return MyTemplates;
}

export async function fetchMyTemplate(id: string) {
    const response = await fetch(`${API_URL}/my-template/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return response;
    // return MyTemplates.find((template) => template.id === id);
}

export async function saveTemplate(template: any) {
    let response: Response;
    if (template.id) {
        response = await fetch(`${API_URL}/update-template`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ template }),
            credentials: "include",
        });
    } else {
        response = await fetch(`${API_URL}/create-template`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ template }),
            credentials: "include",
        });
    }
    return response;
}

export async function cloneTemplate(template: any) {
    const unixTime = new Date().getTime();
    const templateName = `${template.name}_${unixTime}`;
    let response = await fetch(`${API_URL}/create-template`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            template: {
                ...template,
                name: templateName,
            },
        }),
        credentials: "include",
    });
    return response;
}

export async function deleteTemplate(id: string) {
    const response = await fetch(`${API_URL}/delete-template/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return response;
}

export async function fetchMySubmissions() {
    const response = await fetch(`${API_URL}/my-submissions`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return response;
}

export async function fetchAllAcounts() {
    const response = await fetch(`${API_URL}/admin/accounts`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return response;
}
