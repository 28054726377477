import React from "react";
import {
    Form,
    Input,
    Button,
    Col,
    Row,
    TimePicker,
    Select,
    message,
} from "antd";
import {
    PlusOutlined,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/zh_TW";
import dayjs from "dayjs";

const { Option } = Select;
const format = "HH:mm";

function ItineraryForm(options: {
    form: any,
    config: any,
    tourInformationForm: any,
    facilityData: any,
    itineraryDetails: any,
    setItineraryDetails: any,
    firstDay: any,
    lastDay: any,
    facilityTypeList: any,
    otherFacility: any,
    setOtherFacility: any,
}) {
    const {
        form,
        config,
        tourInformationForm,
        facilityData,
        itineraryDetails,
        setItineraryDetails,
        firstDay,
        lastDay,
        facilityTypeList,
        otherFacility,
        setOtherFacility,
    } = options;
    const addFacility = async (name: string) => {
        let obj: any = {};
        console.log("addFacility00", JSON.stringify(itineraryDetails));
        if (!itineraryDetails[name]) {
            // obj[name] = [0]
            obj[name] = { 0: null };
        } else {
            let itinerary = itineraryDetails[name];
            let count = Object.keys(itinerary).length;
            itinerary[count] = null;
            obj[name] = itinerary;
        }
        console.log(
            "addFacility",
            name,
            itineraryDetails[name],
            obj,
            Object.assign(itineraryDetails, obj)
        );
        setItineraryDetails(Object.assign({}, itineraryDetails, obj));
    };

    const onSelectFacilityType = (value: any, index: number, name: string) => {
        console.log("onSelectFacilityType:", value, index, name);
        let obj = itineraryDetails;
        let itinerary = obj[name];
        itinerary[index] = value;
        setItineraryDetails(Object.assign({}, itineraryDetails, obj));

        let itineraryData = form.getFieldValue().itineraryData;
        // itineraryData[name].itinerary[`itinerary${index}`].time = null
        if (value === "住宿") {
            itineraryData[name].itinerary[`itinerary${index}`].time = [
                dayjs("00:00:00", "HH:mm"),
                dayjs("23:59:59", "HH:mm"),
            ];
        }
        itineraryData[name].itinerary[`itinerary${index}`].facility = null;
        itineraryData[name].itinerary[`itinerary${index}`].otherFacility = null;
        form.setFieldsValue({
            itineraryData: itineraryData,
        });
        console.log(
            "onSelectFacilityType1",
            facilityData.get(itineraryDetails[name][index]),
            itineraryData[name].itinerary[`itinerary${index}`].facility
        );
    };

    const onSelectType = () => {
        setOtherFacility(!otherFacility);
    };

    const removeFacility = (name: string) => {
        let obj = itineraryDetails;
        let itinerary = obj[name];
        delete itinerary[Object.keys(itinerary).length - 1];
        setItineraryDetails(Object.assign({}, itineraryDetails, obj));
        let itineraryData = form.getFieldValue().itineraryData;
        delete itineraryData[name].itinerary[
            `itinerary${Object.keys(itinerary).length}`
        ];
        form.setFieldsValue({
            itineraryData: itineraryData,
        });
        console.log("removeFacility", obj, itinerary);
    };

    return (
        <Form
            form={form}
            name="tourItinerary"
            className="tourItinerary"
            layout="vertical"
            // onFinish={onItinerarySubmit}
            scrollToFirstError
        >
            <Form.Item style={{ width: "100%" }}>
                持牌旅行代理商只可安排入境旅行團的參與者光顧該旅行代理商已按行政計劃向旅監局有效註冊的商店（商店名單請按此）
                <a
                    target="_blank"
                    href="https://www.tia.org.hk/tc/compliance/administrative-scheme-for-registered-shops/list-of-registered-shops.html"
                >
                    https://www.tia.org.hk/tc/compliance/administrative-scheme-for-registered-shops/list-of-registered-shops.html
                </a>
                。
            </Form.Item>
            <Form.List name="itineraryData">
                {(fields, { add, remove }) => (
                    <>
                        {fields
                            .slice(0, dayjs(lastDay).diff(firstDay, "day") + 1)
                            .map(({ key, name, ...restField }) => {
                                console.log("list", key, name);
                                return (
                                    <span
                                        key={key}
                                        className="itineraryDetails"
                                        style={{
                                            display: "flex",
                                            marginBottom: 8,
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, "dayOfTrip"]}
                                            initialValue={name + 1}
                                            style={{
                                                display: "inline-block",
                                                width: "10%",
                                            }}
                                        >
                                            第 {name + 1} 天
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            // name={[name, 'dayOfTrip']}
                                            // initialValue={key + 1}
                                            style={{
                                                display: "inline-block",
                                                width: "13%",
                                            }}
                                        >
                                            {firstDay
                                                .add(name, "days")
                                                .format("YYYY-MM-DD")}
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "coachPlateNumber"]}
                                            style={{
                                                display: "none",
                                                width: "40%",
                                            }}
                                            // style={{display: 'inline-block', width: '40%'}}
                                        >
                                            <Input placeholder="巴士車牌號（如有）" />
                                        </Form.Item>
                                        <div
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            {itineraryDetails[name] &&
                                                Object.keys(
                                                    itineraryDetails[name]
                                                ).map((index: any) => {
                                                    return (
                                                        <span key={index}>
                                                            <Row gutter={24}>
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[
                                                                            name,
                                                                            "itinerary",
                                                                            "itinerary" +
                                                                                index,
                                                                            "time",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                type: "array",
                                                                                required: true,
                                                                                message:
                                                                                    "此項必須填寫。",
                                                                            },
                                                                        ]}
                                                                        style={{
                                                                            width: "100%",
                                                                            display:
                                                                                form.getFieldValue()
                                                                                    ?.itineraryData[
                                                                                    name
                                                                                ]
                                                                                    ?.itinerary &&
                                                                                form.getFieldValue()
                                                                                    .itineraryData[
                                                                                    name
                                                                                ]
                                                                                    .itinerary[
                                                                                    `itinerary${index}`
                                                                                ]
                                                                                    ?.facilityType ===
                                                                                    "住宿"
                                                                                    ? "none"
                                                                                    : "block",
                                                                        }}
                                                                    >
                                                                        <TimePicker.RangePicker
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            locale={
                                                                                locale
                                                                            }
                                                                            placeholder={[
                                                                                "開始時間 (時:分)",
                                                                                "結束時間 (時:分)",
                                                                            ]}
                                                                            format={
                                                                                format
                                                                            }
                                                                            minuteStep={
                                                                                5
                                                                            }
                                                                            changeOnBlur={
                                                                                true
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        {...config}
                                                                        name={[
                                                                            name,
                                                                            "itinerary",
                                                                            "itinerary" +
                                                                                index,
                                                                            "facilityType",
                                                                        ]}
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <Select
                                                                            onSelect={(
                                                                                value
                                                                            ) =>
                                                                                onSelectFacilityType(
                                                                                    value,
                                                                                    index,
                                                                                    name as any
                                                                                )
                                                                            }
                                                                            placeholder="請選擇行程類別"
                                                                        >
                                                                            {facilityTypeList.map(
                                                                                (
                                                                                    type: any,
                                                                                    index: number
                                                                                ) => (
                                                                                    <Option
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        value={
                                                                                            type
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            type
                                                                                        }
                                                                                    </Option>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        {...config}
                                                                        name={[
                                                                            name,
                                                                            "itinerary",
                                                                            "itinerary" +
                                                                                index,
                                                                            "facility",
                                                                        ]}
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        {itineraryDetails[
                                                                            name
                                                                        ][
                                                                            index
                                                                        ] &&
                                                                            facilityData.get(
                                                                                itineraryDetails[
                                                                                    name
                                                                                ][
                                                                                    index
                                                                                ]
                                                                            )
                                                                                ?.length >
                                                                                0 && (
                                                                                <Select
                                                                                    placeholder="請選擇行程目的地"
                                                                                    onSelect={(
                                                                                        value
                                                                                    ) =>
                                                                                        onSelectType()
                                                                                    }
                                                                                    showSearch
                                                                                >
                                                                                    {itineraryDetails[
                                                                                        name
                                                                                    ][
                                                                                        index
                                                                                    ] &&
                                                                                        facilityData
                                                                                            .get(
                                                                                                itineraryDetails[
                                                                                                    name
                                                                                                ][
                                                                                                    index
                                                                                                ]
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    type: any,
                                                                                                    index: number
                                                                                                ) => (
                                                                                                    <Option
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        value={
                                                                                                            type
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            type
                                                                                                        }
                                                                                                    </Option>
                                                                                                )
                                                                                            )}
                                                                                </Select>
                                                                            )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            {form.getFieldValue()
                                                                ?.itineraryData[
                                                                name
                                                            ]?.itinerary &&
                                                                form.getFieldValue()
                                                                    .itineraryData[
                                                                    name
                                                                ].itinerary[
                                                                    `itinerary${index}`
                                                                ]
                                                                    ?.facilityType ===
                                                                    "其他" &&
                                                                form.getFieldValue()
                                                                    .itineraryData[
                                                                    name
                                                                ].itinerary[
                                                                    `itinerary${index}`
                                                                ]?.facility ===
                                                                    "自由活動" && (
                                                                    <Row>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            {...config}
                                                                            name={[
                                                                                name,
                                                                                "itinerary",
                                                                                "itinerary" +
                                                                                    index,
                                                                                "otherFacility",
                                                                            ]}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Input placeholder="請填寫自由活動地點" />
                                                                        </Form.Item>
                                                                    </Row>
                                                                )}
                                                            {form.getFieldValue()
                                                                ?.itineraryData[
                                                                name
                                                            ]?.itinerary &&
                                                                form.getFieldValue()
                                                                    .itineraryData[
                                                                    name
                                                                ].itinerary[
                                                                    `itinerary${index}`
                                                                ]
                                                                    ?.facilityType !==
                                                                    "其他" &&
                                                                form.getFieldValue()
                                                                    .itineraryData[
                                                                    name
                                                                ].itinerary[
                                                                    `itinerary${index}`
                                                                ]?.facility ===
                                                                    "其他" && (
                                                                    <Row>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            {...config}
                                                                            name={[
                                                                                name,
                                                                                "itinerary",
                                                                                "itinerary" +
                                                                                    index,
                                                                                "otherFacility",
                                                                            ]}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Input placeholder="請填寫其他活動地點" />
                                                                        </Form.Item>
                                                                    </Row>
                                                                )}
                                                        </span>
                                                    );
                                                })}
                                        </div>
                                        <Form.Item
                                            // {...restField}
                                            // name={[name, 'itinerary']}
                                            style={{
                                                display: "inline-block",
                                                width: "18%",
                                            }}
                                        >
                                            <Button
                                                icon={<PlusOutlined />}
                                                onClick={() =>
                                                    addFacility(name as any)
                                                }
                                            >
                                                添加行程
                                            </Button>
                                        </Form.Item>
                                        {itineraryDetails[name] && (
                                            <Form.Item
                                                style={{
                                                    display: "inline-block",
                                                    width: "18%",
                                                }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        removeFacility(name as any)
                                                    }
                                                >
                                                    删除行程
                                                </Button>
                                            </Form.Item>
                                        )}
                                    </span>
                                );
                            })}
                        <Form.Item
                            style={{
                                width: "49%",
                                display: "inline-block",
                            }}
                        >
                            <Button
                                type="dashed"
                                onClick={() => {
                                    tourInformationForm
                                        .validateFields()
                                        .then(() => {
                                            console.log(
                                                "!!!行程",
                                                fields,
                                                dayjs(lastDay).diff(
                                                    firstDay,
                                                    "day"
                                                )
                                            );
                                            if (
                                                lastDay > firstDay &&
                                                fields.length <
                                                    dayjs(lastDay).diff(
                                                        firstDay,
                                                        "day"
                                                    ) +
                                                        1
                                            ) {
                                                add();
                                            }
                                        })
                                        .catch(() => {
                                            message.error(
                                                "請先正確填寫抵港日期等信息"
                                            );
                                        });
                                }}
                                disabled={
                                    !(
                                        lastDay > firstDay &&
                                        fields.length <
                                            dayjs(lastDay).diff(
                                                firstDay,
                                                "day"
                                            ) +
                                                1
                                    )
                                }
                                block
                                icon={<PlusCircleTwoTone />}
                            >
                                添加日程
                            </Button>
                        </Form.Item>
                        <Form.Item
                            style={{
                                width: "49%",
                                display: "inline-block",
                            }}
                        >
                            <Button
                                type="dashed"
                                onClick={() => {
                                    let obj = Object.assign(
                                        {},
                                        itineraryDetails
                                    );
                                    delete obj[fields.length - 1];
                                    console.log(
                                        "deleteFacility",
                                        JSON.stringify(itineraryDetails),
                                        JSON.stringify(obj)
                                    );
                                    setItineraryDetails(Object.assign({}, obj));
                                    remove(fields.length - 1);
                                }}
                                disabled={
                                    !(lastDay > firstDay) || fields.length <= 0
                                }
                                block
                                icon={<MinusCircleTwoTone />}
                            >
                                刪除日程
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            {/* <Form.Item>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item> */}
        </Form>
    );
}

export default ItineraryForm;
