import React from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form';
import { register } from '../../api';
import styles from './index.module.css';

const RegistrationForm: React.FC = () => {
  const formRef = React.createRef<FormInstance>();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    await register(values.username, values.email, values.password);
    console.log('Received values of form: ', values);
    navigate('/login');
  };

  return (
    <div className={styles.container}>
        <Form
        ref={formRef}
        name="register"
        onFinish={onFinish}
        initialValues={{
            username: '',
            email: '',
            password: '',
            confirm: '',
        }}
        scrollToFirstError
        >
        <Form.Item
            name="username"
            rules={[
            {
                required: true,
                message: 'Please input your nickname!',
                whitespace: true,
            },
            ]}
        >
            <Input placeholder="Username" />
        </Form.Item>

        <Form.Item
            name="email"
            rules={[
            {
                type: 'email',
                message: 'The input is not valid E-mail!',
            },
            {
                required: true,
                message: 'Please input your E-mail!',
            },
            ]}
        >
            <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
            name="password"
            rules={[
            {
                required: true,
                message: 'Please input your password!',
            },
            ]}
            hasFeedback
        >
            <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
            {
                required: true,
                message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
            }),
            ]}
        >
            <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
            <Button type="primary" htmlType="submit">
            Register
            </Button>
        </Form.Item>
        </Form>
    </div>
  );
};

export default RegistrationForm;