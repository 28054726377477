import React, { useContext, useEffect, useRef } from 'react';
import { Table, Button, Space, message } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined, UploadOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { fetchMyTemplates, cloneTemplate, deleteTemplate } from '../../api';
import { AuthContext } from '../../AuthContext';
import { ColumnType } from 'antd/es/table';

const TemplatesPage = () => {
    const authContext = useContext(AuthContext);
    const { setLoggedIn } = authContext || {};
    const [myTemplates, setMyTemplates] = React.useState([] as any[]);
    const navigate = useNavigate();
    const columns: ColumnType<any>[] = [
        {
            title: '名稱',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => {
                return a.name.localeCompare(b.name);
            },
            // defaultSortOrder: 'ascend',
        },
        {
            title: '最後修改時間',
            dataIndex: 'lastModified',
            key: 'lastModified',
            sorter: (a: any, b: any) => {
                return new Date(a.lastModified).getTime() - new Date(b.lastModified).getTime();
            },
            // defaultSortOrder: 'descend',
        },
        {
            title: '動作',
            key: 'action',
            render: (text: string, record: any) => (
                <Space size="middle">
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record.id)}>編輯</Button>
                    <Button icon={<CopyOutlined />} onClick={() => handleCopy(record.id)}>複製</Button>
                    <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)}>刪除</Button>
                </Space>
            ),
        },
    ];
    const fileInputRef = useRef<HTMLInputElement>(null);

    async function fetchData() {
        try {
            const response = await fetchMyTemplates();
            if (response.status === 401) {
                if (setLoggedIn) {
                    setLoggedIn(false);
                }
            }
            else if (response.status !== 200) {
                console.log('Failed to fetch templates');
                return;
            }
            const result = await response.json();
            if (!result.success) {
                console.log('Failed to fetch templates');
                return;
            }
            setMyTemplates(result.data);
        } catch (err) {
            console.log('Failed to fetch templates');
            message.error('無法取得範本');
            setMyTemplates([]);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (key: string) => {
        window.open(`/template/${key}`, '_blank');
    };

    const handleCopy = async (key: string) => {
        const template = myTemplates.find((t) => t.id === key);
        await cloneTemplate(template);
        message.success('複製成功');
        fetchData();
    }

    const handleDelete = async (key: string) => {
        console.log('Delete:', key);
        await deleteTemplate(key);
        message.success('刪除成功');
        fetchData();
    };

    const handleCreate = () => {
        window.open(`/new-form`, '_blank');
    }

    const handleDownload = () => {
        const dataStr = JSON.stringify(myTemplates, null, 2); // pretty print
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        const exportFileDefaultName = 'data.json';

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const contents = e.target?.result;
            if (typeof contents === 'string') {
                const templates = JSON.parse(contents);
                console.log('handleFileChange', templates);
                // Now you have the templates from the file, you can do whatever you want with them
                // For example, you can set them to state or send them to a server
            }
        };
        reader.readAsText(file);
    };

    return (
        <div style={{ padding: '0.5rem' }}>
            <h2>我的範本</h2>
            <Space size="small">
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                <Button icon={<UploadOutlined />} onClick={handleUploadClick} style={{ display: 'none' }}>上傳範本</Button>
                <Button icon={<DownloadOutlined />} onClick={handleDownload}>下載範本</Button>
                <Button icon={<PlusOutlined />} onClick={handleCreate}>新增範本</Button>
            </Space>
            <Table rowKey="id" columns={columns} dataSource={myTemplates} showSorterTooltip={false}/>
        </div>
    );
};

export default TemplatesPage;