import React, { useContext, useState, useEffect, useMemo } from "react";
import locale from "antd/es/date-picker/locale/zh_TW";
import "dayjs/locale/zh-cn";
import {
    Button,
    Form,
    Input,
    Select,
    message,
    notification,
    Result,
    Modal,
    Checkbox,
    Typography,
    Collapse,
} from "antd";
import dayjs from "dayjs";
import "./booking.css";
import { useParams, useLocation } from "react-router-dom";
import {
    fetchMyTemplate,
    saveTemplate,
    submitRegisteredRequest,
    fetchTiaData,
    submitTourContactList,
    submitGeneralTourInformation,
    submitTourSchedule,
    submitTourAccommodation,
    sendConfirmation,
    getTourInformationByRecordId,
    getTourAccommodationFileStream,
} from "../../api";
import AuthenticateForm from "./AuthenticateForm";
import TravelAgentForm from "./TravelAgentForm";
import TourInformationForm from "./TourInformationForm";
import TourContactListForm from "./TourContactListForm";
import ItineraryForm from "./ItineraryForm";
import TourAccommodationForm from "./TourAccommodationForm";
import { AuthContext } from "../../AuthContext";

const { Option } = Select;
const { Paragraph, Text } = Typography;
const format = "HH:mm";

const config = {
    rules: [
        {
            required: true,
            message: "此項為必填。",
        },
    ],
};

const styleConfig = {
    style: {
        display: "inline-block",
        // width: 'calc(50% - 8px)',
    },
};

const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
};

function Booking() {
    const authContext = useContext(AuthContext);
    const { setLoggedIn } = authContext || {};
    const { id, recordStr } = useParams();
    const [templateName, setTemplateName] = useState("");
    const { Panel } = Collapse;
    const [authenticateForm] = Form.useForm();
    const [travelAgentForm] = Form.useForm();
    const [tourInformationForm] = Form.useForm();
    const [tourContactListForm] = Form.useForm();
    const [itineraryForm] = Form.useForm();
    const [tourAccommodationForm] = Form.useForm();
    const [confirmationForm] = Form.useForm();

    const [registrationNumber, setRegistrationNumber] = useState<string>('');
    const [registrationEmail, setRegistrationEmail] = useState(null);
    const [returnedReferenceNumber, setReturnedReferenceNumber] =
        useState(null);
    const [travelAgentEmail, setTravelAgentEmail] = useState(null);
    const [travelAgentName, setTravelAgentName] = useState(null);
    const [travelAgentLicense, setTravelAgentLicense] = useState(null);

    const [submitDate, setSubmitDate] = useState(null);
    const [referenceNumber, setReferenceNumber] = useState<string>('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isFirstRegistration, setIsFirstRegistration] = useState(true);
    const [recordId, setRecordId] = useState(null);
    const [jwtToken, setJwtToken] = useState(null);
    const [firstDay, setFirstDay] = useState<any>(null);
    const [lastDay, setLastDay] = useState<any>(null);
    const [errorArrivalDate, setErrorArrivalDate] = useState(null);
    const [errorDepatureDate, setErrorDepatureDate] = useState(null);
    const [itineraryDetails, setItineraryDetails] = useState({});
    const [facilityTypeList, setFacilityTypeList] = useState([
        "景點",
        "餐饍",
        "住宿",
        "註冊商店",
    ]);
    const [facilityData, setFacilityData] = useState(
        new Map([
            ["景點", ["天星碼頭", "天壇大佛"]],
            ["餐饍", ["元氣壽司", "大家樂"]],
            ["住宿", ["希爾頓酒店", "半島酒店"]],
            [
                "註冊商店",
                [
                    "DFS",
                    "K11",
                    "SOGO",
                    "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh",
                ],
            ],
        ])
    );
    const [otherFacility, setOtherFacility] = useState(false);
    const [travelAgentList, setTravelAgentList] = useState([
        "內地旅行代理商",
        "內地非旅行代理商單位",
    ]);
    const [chinaCityList, setChinaCityList] = useState([]);
    const [currencyList, setCurrencyList] = useState(["RMB", "HKD", "USD"]);
    const [controlPointList, setControlPointList] = useState([
        "羅湖",
        "落馬洲",
        "紅磡",
        "其他",
    ]);
    const [otherArrivalControlPoint, setOtherArrivalControlPoint] =
        useState(false);
    const [otherDepatureControlPoint, setOtherDepatureControlPoint] =
        useState(false);
    const [accommodationTypeList, setAccommodationTypeList] = useState([
        "內地旅行代理商",
        "香港持牌旅行代理商",
        "旅客自行處理",
    ]);
    const [roomInfoRequired, setRoomInfoRequired] = useState(false);
    const [successRegistration, setSuccessRegistration] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [hasTravelAgent, setHasTravelAgent] = useState(null);
    const [transferGuideState, setTransferGuideState] = useState<string>('');
    const [tourStaffState, setTourStaffState] = useState<string>('');
    const [isFormExpired, setIsFormExpired] = useState(false);
    const [formExpiredMessage, setFormExpiredMessage] = useState(null);
    const location = useLocation();
    const isTemplate = useMemo(
        () =>
            location.pathname.includes("template") ||
            location.pathname.includes("new-form"),
        [location.pathname]
    );

    useEffect(() => {
        async function fetchData() {
            try {
                if (isTemplate) {
                    if (id) {
                        const response = await fetchMyTemplate(id);
                        if (response.status === 401) {
                            if (setLoggedIn) {
                                setLoggedIn(false);
                            }
                        } else if (response.status !== 200) {
                            return false;
                        }
                        const result = await response.json();
                        if (!result.success) {
                            return false;
                        }
                        setTemplateName(result.data.name);
                        await setDataToForm(result.data);
                    }
                    initializeData();
                } else if (recordStr) {
                    const decodedRecordStr = atob(recordStr);
                    const recordInfo = JSON.parse(decodedRecordStr);
                    authenticateForm.setFieldsValue({
                        registrationNumber: recordInfo.registrationNumber,
                        registrationEmail: recordInfo.travelAgentEmail,
                    });
                    try {
                        let data: any = await submitRegisteredRequest({
                            registrationNumber: recordInfo.registrationNumber,
                            registrationEmail: recordInfo.travelAgentEmail,
                        });
                        setIsFirstRegistration(data.isFirstRegistration);
                        setRecordId(data.recordId);
                        setJwtToken(data.authToken);
                        travelAgentForm.setFieldsValue({
                            travelAgentName: data.travelAgentName,
                            travelAgentLicense: data.travelAgentLicense,
                            travelAgentPhone: data.travelAgentPhone,
                        });
                        getTourInformation({
                            recordId: data.recordId,
                            referenceNumber: recordInfo.referenceNumber,
                        });
                    } catch (err: any) {
                        message.error(err.failReasonMessage);
                        setFormExpiredMessage(err.failReasonMessage);
                        setIsFormExpired(true);
                    }
                }

                return true;
            } catch (error) {
                console.log("fetchData-error", error);
                return false;
            }
        }
        fetchData();
    }, []);

    const handleOk = () => {
        if (referenceNumber) {
            setIsModalOpen(false);
            getTourInformation({
                recordId: recordId,
                referenceNumber: referenceNumber,
            });
        } else {
            message.warning("請先填寫參考編號！");
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsAuthenticated(false);
    };

    const setDataToForm = async (data: any) => {
        let tourInfo: any = data.tourInfo;
        tourInformationForm.setFieldsValue({
            tourFeePaidPerPersonCurrency: tourInfo.tourFeePaidPerPersonCurrency,
            profitFromTourCurrency: tourInfo.profitFromTourCurrency,
            typeOfTravelAgentInChina: tourInfo.typeOfTravelAgentInChina,
            originCity: tourInfo.originCity?.split("/"),
            tourFeePaidPerPersonAmount: tourInfo.tourFeePaidPerPersonAmount,
            profitFromTourAmount: tourInfo.profitFromTourAmount,
            groupNumber: tourInfo.groupNumber,
            headcount: tourInfo.headcount,
            registrationFee: tourInfo.headcount ? tourInfo.headcount * 4 : 0,
            arrivalDate: tourInfo.arrivalDate
                ? dayjs(tourInfo.arrivalDate, "YYYY-MM-DD")
                : "",
            arrivalTime: tourInfo.arrivalTime
                ? dayjs(tourInfo.arrivalTime, "HH:mm")
                : "",
            arrivalControlPoint: tourInfo.arrivalControlPoint,
            depatureDate: tourInfo.depatureDate
                ? dayjs(tourInfo.depatureDate, "YYYY-MM-DD")
                : "",
            depatureControlPoint: tourInfo.depatureControlPoint,
        });
        setHasTravelAgent(tourInfo.typeOfTravelAgentInChina);
        setFirstDay(tourInfo.arrivalDate ? dayjs(tourInfo.arrivalDate) as any : "");
        setLastDay(tourInfo.depatureDate ? dayjs(tourInfo.depatureDate) as any : "");
        let contactList: any = {};
        if (data.contactList) {
            data.contactList.forEach((val: any) => {
                if (val.identity === "第一負責人") {
                    contactList["firstPerson"] = {
                        identity: val.identity,
                        name: val.name,
                        phone: val.phone,
                    };
                } else if (val.identity === "第二負責人") {
                    contactList["secondPerson"] = {
                        identity: val.identity,
                        name: val.name,
                        phone: val.phone,
                    };
                } else if (val.identity === "內地入境旅行團單位") {
                    contactList["travelAgent"] = {
                        identity: val.identity,
                        name: val.name,
                        phone: val.phone,
                    };
                } else if (val.identity === "內地入境旅行團單位負責人") {
                    contactList["travelAgentPerson"] = {
                        identity: val.identity,
                        name: val.name,
                        phone: val.phone,
                    };
                } else if (val.identity === "接待導遊") {
                    contactList["tourGuide"] = {
                        identity: val.identity,
                        name: val.name,
                        phone: val.phone,
                        licenseNumber: val.licenseNumber,
                    };
                } else if (val.identity === "接關導遊") {
                    val.name || val.phone || val.licenseNumber
                        ? setTransferGuideState("editable")
                        : setTransferGuideState("empty");
                    contactList["transferGuide"] = {
                        identity: val.identity,
                        name: val.name ? val.name : null,
                        phone: val.phone ? val.phone : null,
                        licenseNumber: val.licenseNumber
                            ? val.licenseNumber
                            : null,
                    };
                } else if (val.identity === "隨團工作人員") {
                    val.name || val.phone
                        ? setTourStaffState("editable")
                        : setTourStaffState("empty");
                    contactList["tourStaff"] = {
                        identity: val.identity,
                        name: val.name ? val.name : null,
                        phone: val.phone ? val.phone : null,
                    };
                }
            });
        }
        tourContactListForm.setFieldsValue(contactList);
        let itineraryDetails: any = {};
        let scheduleList;
        if (data.scheduleList) {
            scheduleList = data.scheduleList.map((val: any, index: number) => {
                let itinerary: any = {};
                itineraryDetails[index] = {};
                val.route &&
                    val.route.forEach((r: any, i: number) => {
                        if (r.facility && r.facility.split(",").length > 1) {
                            itinerary[`itinerary${i}`] = {
                                time: [
                                    dayjs(r.fromTime, "HH:mm"),
                                    dayjs(r.toTime, "HH:mm"),
                                ],
                                facilityType: r.facilityType,
                                facility: r.facility.split(",")[0],
                                otherFacility: r.facility.split(",")[1],
                            };
                        } else {
                            itinerary[`itinerary${i}`] = {
                                time: [
                                    dayjs(r.fromTime, "HH:mm"),
                                    dayjs(r.toTime, "HH:mm"),
                                ],
                                facilityType: r.facilityType,
                                facility: r.facility,
                                otherFacility: null,
                            };
                        }
                        itineraryDetails[index][i] = r.facilityType;
                    });
                console.log("!!!reload--itinerary", itinerary);
                return {
                    dayOfTrip: val.dayOfTrip,
                    coachPlateNumber: val.coachPlateNumber
                        ? val.coachPlateNumber
                        : null,
                    itinerary: val.route ? itinerary : val.route,
                };
            });
        }
        itineraryForm.setFieldsValue({
            itineraryData: scheduleList,
        });
        console.log(
            "!!!reload--itineraryDetails",
            itineraryDetails,
            facilityData
        );
        setItineraryDetails(itineraryDetails);

        if (dayjs(lastDay).diff(firstDay, "day") + 1 > 1) {
            let accommodation;
            if (data.accommodation?.length > 0) {
                let accommodationList = data.accommodation;
                let restOfAccommodationList = data.accommodation.slice(
                    1,
                    data.accommodation.length
                );
                let fileList: any[] = [];
                if (accommodationList[0].attachmentLocation) {
                    fileList[0] = await base64ToUrl(
                        accommodationList[0].attachmentLocation
                    );
                    console.log("!!!reload--fileList", fileList);
                }
                setFileList(fileList);
                restOfAccommodationList.forEach(async (val: any, idx: number) => {
                    if (val.attachmentLocation) {
                        let newFileList = [...fileList];
                        newFileList[idx + 1] = await base64ToUrl(
                            val.attachmentLocation
                        );
                        setFileList(newFileList);
                    }
                });

                accommodation = {
                    accommodationProvider: data.accommodationProvider,
                    firstRoom: {
                        name: accommodationList[0].name
                            ? accommodationList[0].name
                            : null,
                        contactName: accommodationList[0].contactName
                            ? accommodationList[0].contactName
                            : null,
                        phoneNumber: accommodationList[0].phoneNumber
                            ? accommodationList[0].phoneNumber
                            : null,
                        numberOfRooms: accommodationList[0].numberOfRooms
                            ? accommodationList[0].numberOfRooms
                            : null,
                        attachmentLocation:
                            accommodationList[0].attachmentLocation,
                        dragger: accommodationList[0].dragger,
                    },
                    otherRoomInfo: restOfAccommodationList.map((val: any) => {
                        return {
                            name: val.name ? val.name : null,
                            contactName: val.contactName
                                ? val.contactName
                                : null,
                            phoneNumber: val.phoneNumber
                                ? val.phoneNumber
                                : null,
                            numberOfRooms: val.numberOfRooms
                                ? val.numberOfRooms
                                : null,
                            attachmentLocation: val.attachmentLocation
                                ? val.attachmentLocation
                                : null,
                            dragger: val.dragger ? val.dragger : null,
                        };
                    }),
                };
            }
            tourAccommodationForm.setFieldsValue(accommodation);
            data.accommodationProvider === "香港持牌旅行代理商"
                ? setRoomInfoRequired(true)
                : setRoomInfoRequired(false);
        } else {
            tourAccommodationForm.setFieldsValue({
                accommodationProvider: data.accommodationProvider,
            });
        }
    };

    const getTourInformation = async (fields: any) => {
        console.log("!!!getTourInformation00", fields);
        try {
            let res = await getTourInformationByRecordId(fields);
            await setDataToForm(res);
            await initializeData();
            setIsAuthenticated(true);
            return true;
        } catch (error: any) {
            console.log("!!!getTourInformation--error", error);
            setIsAuthenticated(false);
            openNotificationWithIcon("error", error);
            if (error.failReasonCode === "NO_AUTH_REGISTERED") {
                Modal.warning({
                    okText: "確認",
                    title: "參考編號有誤",
                    content: "參考編號有誤，無法獲取註冊數據。",
                });
            }
            return false;
        }
    };

    const base64ToUrl = async (attachmentLocation: any) => {
        try {
            let res: any = await getTourAccommodationFileStream(attachmentLocation);
            let blob = new Blob([res.data], {
                type: "application/octet-stream",
            }); // 核心代码
            let url = window.URL.createObjectURL(blob);
            // let a = document.getElementById('attachmentLink0')
            // a.href = url
            var filename = "";
            var disposition = res.headers["content-disposition"];
            if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }
            console.log("!!!fileStream", url, fileList, disposition, filename);
            return {
                fileUrl: url,
                fileName: filename,
            };
        } catch (error) {
            console.log("base64ToUrl: ", error);
            message.error(`加載住址證明失敗!, ${error}`);
            return false;
        }
    };

    const [api, contextHolder] = notification.useNotification();
    interface NotificationInstance {
        [key: string]: any;
    }

    const openNotificationWithIcon = (type: string, data: any) => {
        if (type === "error") {
            (api as NotificationInstance)[type]({
                message: data.failReasonCode,
                description: data.failReasonMessage,
                duration: 10,
            });
        } else {
            (api as NotificationInstance)[type]({
                message: data,
                duration: 10,
            });
        }
    };

    const onSaveTemplate = async () => {
        if (!templateName) {
            message.error("請填寫範本名稱");
            return;
        }
        let tourInformationData = tourInformationForm.getFieldsValue(true);
        let tourContactListData = tourContactListForm.getFieldsValue(true);
        let itineraryData = itineraryForm.getFieldsValue(true);
        let tourAccommodationData = tourAccommodationForm.getFieldsValue(true);
        let tourInformation = onTourInformationSubmit(tourInformationData);
        let tourContactList: any = onTourContactListSubmit(tourContactListData);
        let tourSchedule = onItinerarySubmit(itineraryData);
        let tourAccommodation = onTourAccommodationSubmit(
            tourAccommodationData
        );
        const template = {
            id,
            name: templateName,
            tourInfo: tourInformation,
            contactList: tourContactList.contactList,
            scheduleList: tourSchedule.scheduleList,
            accommodation: tourAccommodation.accommodation,
            accommodationProvider: tourAccommodation.accommodationProvider,
        };
        console.log("onSaveTemplate", template);
        const response = await saveTemplate(template);
        message.success("範本儲存成功");
    };

    const onFormSubmission = async () => {
        if (!isAuthenticated) {
            message.error("請先認證");
            return;
        }

        Promise.all([
            tourInformationForm.validateFields(),
            tourContactListForm.validateFields(),
            itineraryForm.validateFields(),
            tourAccommodationForm.validateFields(),
            confirmationForm.validateFields(),
        ])
            .then((results) => {
                console.log("promiseAlll-success", results);
                if (lastDay < firstDay) {
                    message.error("請正確填寫抵港日期和離港日期");
                    return;
                }
                if (
                    !results[2]?.itineraryData ||
                    results[2]?.itineraryData?.length < 1 ||
                    !results[2]?.itineraryData[0].itinerary
                ) {
                    message.error("請至少添加一天的日程");
                    return;
                }
                if (
                    results[3]?.firstRoom &&
                    !results[3].firstRoom?.attachmentLocation
                ) {
                    message.error("請上傳住址證明");
                    return;
                }
                if (results[4]?.confirm === false) {
                    message.error(
                        "請细阅上述文字并确保本登记表格上的所有资料均正确无误，並進行勾選"
                    );
                    return;
                }
                let tourInformation = onTourInformationSubmit(results[0]);
                let tourContactList = onTourContactListSubmit(results[1]);
                let tourSchedule = onItinerarySubmit(results[2]);
                let tourAccommodation = onTourAccommodationSubmit(results[3]);
                console.log(
                    "promiseAlll!!!!!!!!!!!!",
                    tourInformation,
                    tourContactList,
                    tourSchedule,
                    tourAccommodation
                );

                Promise.all([
                    submitGeneralTourInformation({ ...tourInformation }),
                    submitTourContactList({ ...tourContactList }),
                    submitTourSchedule({ ...tourSchedule }),
                    submitTourAccommodation({ ...tourAccommodation }),
                ])
                    .then((res: any) => {
                        console.log("promiseAlll-success1", res);
                        let noErr = true;
                        res.map((r: any) => {
                            if (r.data.responseCode === "FAILED") {
                                noErr = false;
                                setSuccessRegistration(false);
                                openNotificationWithIcon("error", r.data);
                            }
                        });
                        if (noErr) {
                            try {
                                sendConfirmation(registrationNumber).then(
                                    (res: any) => {
                                        setReturnedReferenceNumber(
                                            res.referenceNumber
                                        );
                                        setTravelAgentEmail(
                                            res.travelAgentEmail
                                        );
                                        setTravelAgentName(res.travelAgentName);
                                        setTravelAgentLicense(
                                            res.travelAgentLicense
                                        );
                                        setRegistrationNumber(
                                            res.registrationNumber
                                        );
                                        setSubmitDate(res.submitDate);
                                        setSuccessRegistration(true);
                                    }
                                );
                            } catch (error: any) {
                                message.error(error);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("submit-error", err);
                        message.error("提交失敗, 請修改後重試!");
                    });
            })
            .catch((error) => {
                console.log("promiseAlll-error", error);
                message.error("驗證失敗, 請修改後重試!");
            });
    };

    const onAuthentication = async (fields: any) => {
        try {
            let data: any = await submitRegisteredRequest({ ...fields });
            console.log("onAuthentication1: ", data);
            setRegistrationNumber(fields.registrationNumber);
            setIsFirstRegistration(data.isFirstRegistration);
            setRecordId(data.recordId);
            setJwtToken(data.authToken);
            travelAgentForm.setFieldsValue({
                travelAgentName: data.travelAgentName,
                travelAgentLicense: data.travelAgentLicense,
                travelAgentPhone: data.travelAgentPhone,
            });
            message.success("驗證成功");
            if (!data.isFirstRegistration) {
                setIsModalOpen(true);
                return;
            } else {
                setIsAuthenticated(true);
            }
            return true;
        } catch (error: any) {
            console.log("onAuthentication2: ", error);
            message.error(`驗證失敗, ${error.failReasonMessage}, 請重試!`);
            return false;
        }
    };
    const initializeData = async () => {
        try {
            const tiaData = await fetchTiaData();
            let typeOfTravelAgentList = tiaData.typeOfTravelAgentList;
            let currencyList = tiaData.currencyList;
            let controlPointList = tiaData.controlPointList;
            let typeOfFacilityList = tiaData.typeOfFacilityList;
            let facilityList = tiaData.facilityList;
            let typeOfAccommdation = tiaData.typeOfAccommdation;
            setTravelAgentList(typeOfTravelAgentList);
            let chinaCityList = tiaData.chinaCityList.map((data: any) => {
                return {
                    label: data.province,
                    value: data.province,
                    children: data.cities.map((city: string) => {
                        return {
                            label: city,
                            value: city,
                        };
                    }),
                };
            });
            setChinaCityList(chinaCityList);
            setCurrencyList(currencyList);
            setControlPointList(controlPointList);
            setFacilityTypeList(typeOfFacilityList);
            // setFacilityList(list.facilityList)
            let facilityData = new Map();
            facilityList.map((facility: any) => {
                facilityData.set(facility.facilityType, facility.facility);
            });
            setFacilityData(facilityData);
            setAccommodationTypeList(typeOfAccommdation);
            return true;
        } catch (error: any) {
            message.error(`${error.failReasonMessage}`);
            return false;
        }
    };

    const onTourInformationSubmit = (fields: any) => {
        let tourInformation = Object.assign({}, fields);
        tourInformation["arrivalDate"] =
            tourInformation.arrivalDate?.format("YYYY-MM-DD");
        tourInformation["arrivalTime"] =
            tourInformation.arrivalTime?.format("HH:mm");
        tourInformation["depatureDate"] =
            tourInformation.depatureDate?.format("YYYY-MM-DD");
        tourInformation["originCity"] = tourInformation.originCity?.join("/");
        tourInformation["recordId"] = recordId;
        if (tourInformation["depatureControlPoint"] === "其他") {
            tourInformation["depatureControlPoint"] =
                tourInformation["otherDepatureControlPoint"];
            delete tourInformation.otherDepatureControlPoint;
        }
        if (tourInformation["arrivalControlPoint"] === "其他") {
            tourInformation["arrivalControlPoint"] =
                tourInformation["otherArrivalControlPoint"];
            delete tourInformation.otherArrivalControlPoint;
        }
        // console.log('onTourInformationSubmit: ', fields, tourInformation);
        return tourInformation;
        // try {
        //     await submitGeneralTourInformation({ ...fields });
        //     message.success('Added successfully');
        //     return true;
        // } catch (error) {
        //     message.error('Adding failed, please try again!');
        //     return false;
        // }
    };
    const onTourContactListSubmit = (fields: any) => {
        let tourContactList: any = {};
        // let formData = Object.assign({}, tourContactListForm.getFieldValue())
        tourContactList["recordId"] = recordId;
        tourContactList["contactList"] = Object.values(fields);
        console.log("onTourContactListSubmit:", fields, tourContactList);
        return tourContactList;
        // try {
        //     await submitTourContactList({ ...fields });
        //     message.success('Added successfully');
        //     return true;
        // } catch (error) {
        //     message.error('Adding failed, please try again!');
        //     return false;
        // }
    };
    const onItinerarySubmit = (fields: any) => {
        let scheduleList;
        if (fields.itineraryData) {
            scheduleList = fields.itineraryData
                .slice(0, dayjs(lastDay).diff(firstDay, "day") + 1)
                .map((val: any) => {
                    let obj: any = {};
                    obj["coachPlateNumber"] = val.coachPlateNumber;
                    obj["dayOfTrip"] = val.dayOfTrip;
                    if (val.itinerary) {
                        obj["route"] = Object.values(val.itinerary).map((i: any) => {
                            let route: any = {};
                            route["fromTime"] = i.time[0].format("HH:mm");
                            route["toTime"] = i.time[1].format("HH:mm");
                            route["facilityType"] = i.facilityType;
                            if (
                                i.facility === "自由活動" ||
                                i.facility === "其他"
                            ) {
                                route["facility"] =
                                    i.facility + ", " + i.otherFacility;
                            } else {
                                route["facility"] = i.facility;
                            }
                            return route;
                        });
                    } else {
                        obj["route"] = null;
                    }

                    return obj;
                });
        }
        let tourSchedule = {
            recordId,
            scheduleList,
        };
        console.log("onItinerarySubmit: ", fields, tourSchedule);
        return tourSchedule;
    };

    const onTourAccommodationSubmit = (fields: any) => {
        let accommodation;
        if (fields.accommodationProvider === "香港持牌旅行代理商") {
            accommodation = [];
            accommodation.push(fields.firstRoom);
            if (fields.otherRoomInfo)
                accommodation = accommodation.concat(fields.otherRoomInfo);
        }
        let tourAccommodation = {
            recordId,
            accommodationProvider: fields.accommodationProvider,
            accommodation,
        };
        console.log("onTourAccommodationSubmit: ", fields, tourAccommodation);
        return tourAccommodation;
    };

    const handleSuccessRegistrationCancel = () => {
        setSuccessRegistration(false);
        authenticateForm.resetFields();
        travelAgentForm.resetFields();
        setIsAuthenticated(false);
        setRegistrationNumber('');
        setRegistrationEmail(null);
        setRecordId(null);
        setJwtToken(null);
    };

    const handleTemplateNameChange = (event: any) => {
        setTemplateName(event.target.value);
    };

    return (
        <>
            {contextHolder}
            <div className="booking">
                <div hidden={!isTemplate}>
                    <Typography.Title level={5}>範本名稱</Typography.Title>
                    <Input
                        value={templateName}
                        onChange={handleTemplateNameChange}
                    />
                </div>
                <div className="head" style={{ padding: "2.5em 52px" }}>
                    <h2>內地入境旅行團登記表格 (v0.4.1)</h2>
                    <p>
                        標有「*」為必須填寫；一份登記表格只可登記一個內地入境旅行團。
                    </p>
                </div>
                <AuthenticateForm
                    form={authenticateForm}
                    onAuthentication={onAuthentication}
                    isFormExpired={isFormExpired}
                    config={config}
                />
                <Modal
                    title="內地入境旅行團登記"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="確認"
                    cancelText="取消"
                >
                    <p>該旅行團已進行登記，是否需要更新資料</p>
                    <Input
                        placeholder="請輸入參考編號"
                        onChange={(e) => {
                            setReferenceNumber(e.target.value);
                        }}
                    />
                </Modal>
                {!isFormExpired && (
                    <>
                        <Collapse defaultActiveKey={["1"]}>
                            <Panel
                                header={
                                    <h3>
                                        第 I
                                        部分：香港持牌旅行代理商(系統自動填寫,如要更新資料請電郵mreg@tia.org.hk與本局職員聯絡。)
                                    </h3>
                                }
                                key="1"
                                forceRender
                            >
                                <TravelAgentForm
                                    form={travelAgentForm}
                                    config={config}
                                ></TravelAgentForm>
                            </Panel>
                            <Panel
                                header={<h3>第 II 部分：內地入境旅行團資料</h3>}
                                key="2"
                                forceRender
                            >
                                <TourInformationForm
                                    form={tourInformationForm}
                                    config={config}
                                    errorArrivalDate={errorArrivalDate}
                                    setErrorArrivalDate={setErrorArrivalDate}
                                    currencyList={currencyList}
                                    chinaCityList={chinaCityList}
                                    travelAgentList={travelAgentList}
                                    firstDay={firstDay}
                                    setFirstDay={setFirstDay}
                                    lastDay={lastDay}
                                    setLastDay={setLastDay}
                                    errorDepatureDate={errorDepatureDate}
                                    setErrorDepatureDate={setErrorDepatureDate}
                                    otherDepatureControlPoint={
                                        otherDepatureControlPoint
                                    }
                                    setOtherDepatureControlPoint={
                                        setOtherDepatureControlPoint
                                    }
                                    hasTravelAgent={hasTravelAgent}
                                    setHasTravelAgent={setHasTravelAgent}
                                    controlPointList={controlPointList}
                                    otherArrivalControlPoint={
                                        otherArrivalControlPoint
                                    }
                                    setOtherArrivalControlPoint={
                                        setOtherArrivalControlPoint
                                    }
                                ></TourInformationForm>
                            </Panel>
                            <Panel
                                header={
                                    <h3>
                                        第 III
                                        部分：內地旅行代理商／內地非旅行代理商單位、接待導遊等資料
                                    </h3>
                                }
                                key="3"
                                forceRender
                            >
                                <TourContactListForm
                                    form={tourContactListForm}
                                    config={config}
                                    hasTravelAgent={hasTravelAgent}
                                    isFirstRegistration={isFirstRegistration}
                                    transferGuideState={transferGuideState}
                                    setTransferGuideState={setTransferGuideState}
                                    tourStaffState={tourStaffState}
                                    setTourStaffState={setTourStaffState}
                                ></TourContactListForm>
                            </Panel>
                            <Panel
                                header={
                                    <h3>
                                        {" "}
                                        第 IV 部分：內地入境旅行團行程
                                        <span
                                            style={{
                                                backgroundColor: "yellow",
                                            }}
                                        >
                                            （須按照入境旅行團行程安排，就每項行程逐一添加行程資料）
                                        </span>
                                    </h3>
                                }
                                key="4"
                                forceRender
                            >
                                <ItineraryForm
                                    form={itineraryForm}
                                    config={config}
                                    tourInformationForm={tourInformationForm}
                                    facilityData={facilityData}
                                    itineraryDetails={itineraryDetails}
                                    setItineraryDetails={setItineraryDetails}
                                    firstDay={firstDay}
                                    lastDay={lastDay}
                                    facilityTypeList={facilityTypeList}
                                    otherFacility={otherFacility}
                                    setOtherFacility={setOtherFacility}
                                ></ItineraryForm>
                            </Panel>
                            {dayjs(lastDay).diff(firstDay, "day") + 1 > 1 && (
                                <>
                                    <Panel
                                        header={
                                            <h3>
                                                第 V 部分：負責預訂住宿,
                                                房間供應商及房間資料
                                            </h3>
                                        }
                                        key="5"
                                        forceRender
                                    >
                                        <TourAccommodationForm
                                            form={tourAccommodationForm}
                                            config={config}
                                            accommodationTypeList={
                                                accommodationTypeList
                                            }
                                            roomInfoRequired={roomInfoRequired}
                                            setRoomInfoRequired={
                                                setRoomInfoRequired
                                            }
                                            fileList={fileList}
                                            setFileList={setFileList}
                                            jwtToken={jwtToken}
                                            recordId={recordId}
                                        ></TourAccommodationForm>
                                    </Panel>
                                </>
                            )}
                        </Collapse>
                        <Form
                            form={confirmationForm}
                            name="confirmation"
                            layout="vertical"
                        >
                            <Form.Item
                                style={{ width: "100%", color: "#ea3223" }}
                            >
                                <strong>
                                    持牌旅行代理商須自行確保本登記表格上的所有資訊均正確無誤，及預繳登記費的結餘足以繳付本登記表格上的內地入境旅行團的登記費。持牌旅行代理商如提供不正確資料或預繳登記費餘額不足，會被紀律處分。
                                </strong>
                            </Form.Item>
                            <Form.Item
                                name="confirm"
                                valuePropName="checked"
                                {...config}
                                style={{ width: "100%" }}
                            >
                                <Checkbox>
                                    <span style={{ color: "red" }}>* </span>
                                    我已細閱上述文字並確保本登記表格上的所有資料均正確無誤。
                                </Checkbox>
                            </Form.Item>
                        </Form>
                        <Form.Item>
                            <>
                                {isTemplate && (
                                    <Button
                                        type="default"
                                        onClick={onSaveTemplate}
                                        style={{ marginRight: "0.5rem" }}
                                    >
                                        儲存範本
                                    </Button>
                                )}
                            </>
                            <Button type="primary" onClick={onFormSubmission}>
                                提交
                            </Button>
                        </Form.Item>
                        <Modal
                            open={successRegistration}
                            footer={null}
                            maskClosable={false}
                            onCancel={handleSuccessRegistrationCancel}
                        >
                            <Result
                                status="success"
                                title="感謝!"
                                subTitle={`請記下參考編號：${returnedReferenceNumber}，以便後續編輯。`}
                            >
                                <div className="desc">
                                    <Paragraph>
                                        <Text
                                            strong
                                            style={{
                                                fontSize: 16,
                                            }}
                                        >
                                            旅遊業監理局的電子登記系統已收到你所提交的登記表格，而登記表格副本將會發送到你已登記的電郵地址
                                            <a>{travelAgentEmail}</a>
                                            ，敬請查收。
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        持牌旅行代理商名稱： {travelAgentName}
                                    </Paragraph>
                                    <Paragraph>
                                        旅行代理商牌照號碼：{" "}
                                        {travelAgentLicense}
                                    </Paragraph>
                                    <Paragraph>
                                        登記貼紙編號： {registrationNumber}
                                    </Paragraph>
                                    <Paragraph>
                                        提交時間： {submitDate}
                                    </Paragraph>
                                    <Paragraph>
                                        <Text
                                            strong
                                            style={{
                                                fontSize: 16,
                                            }}
                                        >
                                            本局建議你儲存此版面以作記錄，如你於半小時後仍未收到登配表格的副本，請檢查電郵信箱的[垃圾郵件]檔案，如經檢查後仍未有發現，則請將上述資料電子郵件至
                                            <a>mreg@tia.org.hk</a>
                                            ，以便本局跟進。
                                        </Text>
                                    </Paragraph>
                                </div>
                            </Result>
                        </Modal>
                    </>
                )}
            </div>
        </>
    );
}

export default Booking;
