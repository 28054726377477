import React from "react";
import { Form, Input } from "antd";

function TravelAgentForm(options: { form: any, config: any }) {
    const { form, config } = options;
    return (
        <Form
            form={form}
            name="travelAgent"
            layout="vertical"
            scrollToFirstError
        >
            <Form.Item
                name="travelAgentName"
                label="持牌旅行代理商名稱"
                {...config}
                style={{
                    display: "inline-block",
                }}
                extra="系統會自動顯示持牌旅行代理商名稱，登記者無須填寫"
            >
                <Input style={{ pointerEvents: "none" }} disabled />
            </Form.Item>
            <Form.Item
                name="travelAgentLicense"
                label="持牌旅行代理商牌照號碼"
                {...config}
                style={{
                    display: "inline-block",
                }}
                extra="系統會自動顯示持牌旅行代理商牌照號碼，登記者無須填寫"
            >
                <Input style={{ pointerEvents: "none" }} disabled />
            </Form.Item>
            <Form.Item
                name="travelAgentPhone"
                label="持牌旅行代理商電話號碼"
                {...config}
            >
                <Input style={{ pointerEvents: "none" }} disabled />
            </Form.Item>
        </Form>
    );
}

export default TravelAgentForm;
