import React, { useState } from "react";
import {
    Form,
    Input,
    Select,
    Cascader,
    InputNumber,
    DatePicker,
    TimePicker,
    notification,
} from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import dayjs from "dayjs";

const format = "HH:mm";
const { Option } = Select;

function TourInformationForm(options: {
    form: any,
    config: any,
    errorArrivalDate: any,
    setErrorArrivalDate: any,
    currencyList: any,
    chinaCityList: any,
    travelAgentList: any,
    firstDay: any,
    setFirstDay: any,
    lastDay: any,
    setLastDay: any,
    errorDepatureDate: any,
    setErrorDepatureDate: any,
    otherDepatureControlPoint: any,
    setOtherDepatureControlPoint: any,
    hasTravelAgent: any,
    setHasTravelAgent: any,
    controlPointList: any,
    otherArrivalControlPoint: any,
    setOtherArrivalControlPoint: any,
}) {
    const {
        form,
        config,
        errorArrivalDate,
        setErrorArrivalDate,
        currencyList,
        chinaCityList,
        travelAgentList,
        firstDay,
        setFirstDay,
        lastDay,
        setLastDay,
        errorDepatureDate,
        setErrorDepatureDate,
        otherDepatureControlPoint,
        setOtherDepatureControlPoint,
        hasTravelAgent,
        setHasTravelAgent,
        controlPointList,
        otherArrivalControlPoint,
        setOtherArrivalControlPoint,
    } = options;
    
    const [api, contextHolder] = notification.useNotification();
    interface NotificationInstance {
        [key: string]: any;
    }

    const openNotificationWithIcon = (type: string, data: any) => {
        if (type === "error") {
            (api as NotificationInstance)[type]({
                message: data.failReasonCode,
                description: data.failReasonMessage,
                duration: 10,
            });
        } else {
            (api as NotificationInstance)[type]({
                message: data,
                duration: 10,
            });
        }
    };

    const tourFeePaidPerPersonCurrencySelector = (
        <Form.Item
            name="tourFeePaidPerPersonCurrency"
            initialValue={currencyList[0]}
            noStyle
        >
            <Select style={{ width: 80 }}>
                {currencyList.map((type: string, index: number) => (
                    <Option key={index} value={type}>
                        {type}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );

    const profitFromTourCurrencySelector = (
        <Form.Item
            name="profitFromTourCurrency"
            initialValue={currencyList[0]}
            noStyle
        >
            <Select style={{ width: 80 }}>
                {currencyList.map((type: string, index: number) => (
                    <Option key={index} value={type}>
                        {type}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );

    return (
        <Form
            form={form}
            name="tourInformation"
            layout="vertical"
            scrollToFirstError
        >
            <Form.Item
                name="typeOfTravelAgentInChina"
                label="組織內地入境旅行團的單位"
                {...config}
            >
                <Select
                    placeholder="請選擇旅行團單位"
                    onSelect={(value) => {
                        setHasTravelAgent(value);
                    }}
                >
                    {travelAgentList.map((type: string, index: number) => (
                        <Option key={index} value={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="originCity"
                label="內地入境旅行團出發地點"
                {...config}
                extra="如果團友來自不同省市而在深圳集合，請先選擇廣東省，再選擇深圳市"
            >
                {/* <Select placeholder="請選擇出發地點">
                {originCityList.map((type, index) => (<Option key={index} value={type}>{type}</Option>))}
            </Select> */}
                <Cascader
                    options={chinaCityList}
                    placeholder="請選擇出發地點"
                />
            </Form.Item>
            <Form.Item
                name="tourFeePaidPerPersonAmount"
                label="每位旅客所繳交的參團費用"
                {...config}
            >
                <InputNumber
                    addonAfter={tourFeePaidPerPersonCurrencySelector}
                    style={{
                        width: "100%",
                    }}
                    min="0"
                />
            </Form.Item>
            <Form.Item
                name="profitFromTourAmount"
                label="香港持牌旅行代理商就接待本團而向組織者提供的報價或從組織者獲取的費用總額"
                {...config}
            >
                <InputNumber
                    addonAfter={profitFromTourCurrencySelector}
                    style={{
                        width: "100%",
                    }}
                    min="0"
                />
            </Form.Item>
            <Form.Item
                name="groupNumber"
                label="團號"
                {...config}
                extra="同一日抵達香港的內地入境旅行團不可使用相同團號"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="headcount"
                label="旅行團人數"
                {...config}
                style={
                    hasTravelAgent !== "內地非旅行代理商單位"
                        ? {
                              display: "inline-block",
                          }
                        : {}
                }
            >
                <InputNumber
                    style={{ width: "100%" }}
                    min={1}
                    onChange={(value: any) =>
                        form.setFieldValue("registrationFee", value * 4)
                    }
                />
            </Form.Item>
            {hasTravelAgent !== "內地非旅行代理商單位" && (
                <Form.Item
                    name="registrationFee"
                    label="登記費用（自動計算）"
                    {...config}
                    style={{
                        display: "inline-block",
                    }}
                >
                    <Input
                        style={{
                            pointerEvents: "none",
                        }}
                    />
                </Form.Item>
            )}

            <Form.Item
                name="arrivalDate"
                label="抵港日期"
                rules={[
                    {
                        type: "object",
                        required: true,
                        message: "請選擇日期!",
                    },
                ]}
                style={{
                    display: "inline-block",
                }}
            >
                <DatePicker
                    style={{ width: "100%" }}
                    placeholder="請選擇日期"
                    format="YYYY-MM-DD"
                    status={errorArrivalDate}
                    onChange={(date, dateString) => {
                        console.log("!!!DatePicker", date, dateString);
                        if (lastDay && date > lastDay) {
                            console.log(
                                "!!!DatePicker--errorArrivalDate",
                                lastDay < date
                            );
                            setErrorArrivalDate("error");
                            openNotificationWithIcon(
                                "warning",
                                "請確保離港日期為抵港日期當日或之後的日子，否則無法提交修改！"
                            );
                        } else {
                            setErrorArrivalDate(null);
                        }
                        setFirstDay(date);
                    }}
                />
                {/* <DatePicker style={{width: '100%'}} placeholder='請選擇日期' format="YYYY-MM-DD" disabledDate={disabledDate} onChange={(date, dateString) => {
                console.log('!!!DatePicker', date, dateString)
                setFirstDay(date)
            }}/> */}
            </Form.Item>
            <Form.Item
                name="arrivalTime"
                label="預計抵港時間 (24小時制 , 時:分)"
                rules={[
                    {
                        type: "object",
                        required: true,
                        message: "請選擇時間!",
                    },
                ]}
                style={{
                    display: "inline-block",
                }}
            >
                <TimePicker
                    placeholder="請選擇時間"
                    locale={locale}
                    style={{ width: "100%" }}
                    format={format}
                    minuteStep={5}
                    changeOnBlur={true}
                />
            </Form.Item>
            {/* <Form.Item style={{width: '100%'}}>
            所有內地入境旅行團均須於該團預計抵達香港或旅行代理商預計為有關旅行團獲取服務的最少24小時前登記<br></br>  
            例如：如內地入境旅行團於1月10日上午8時抵港，旅行代理商最遲須於1月9日上午8時或之前登記該團
        </Form.Item> */}

            <Form.Item
                name="arrivalControlPoint"
                label="入境關口"
                {...config}
                style={{
                    display: "inline-block",
                }}
            >
                <Select
                    placeholder="請選擇入境關口"
                    onSelect={(value) => {
                        console.log("setOtherArrivalControlPoint", value);
                        if (value === "其他") {
                            setOtherArrivalControlPoint(true);
                        } else {
                            setOtherArrivalControlPoint(false);
                        }
                    }}
                >
                    {controlPointList.map((type: string, index: number) => (
                        <Option key={index} value={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {otherArrivalControlPoint && (
                <Form.Item
                    name="otherArrivalControlPoint"
                    label="入境關口如選擇其他，請註明"
                    style={{
                        display: "inline-block",
                    }}
                >
                    <Input />
                </Form.Item>
            )}

            <Form.Item
                name="depatureDate"
                label="離港日期"
                rules={[
                    {
                        type: "object",
                        required: true,
                        message: "請選擇日期!",
                    },
                ]}
            >
                <DatePicker
                    style={{ width: "100%" }}
                    placeholder="請選擇日期"
                    status={errorDepatureDate}
                    disabledDate={(current) => {
                        // Can not select days before today and today
                        // return current && (firstDay ? current < firstDay : current < dayjs().endOf('day'));
                        return current && firstDay && current < firstDay;
                    }}
                    onChange={(date, dateString) => {
                        console.log(
                            "!!!DatePicker-last",
                            date,
                            dateString,
                            dayjs(date).diff(firstDay, "day") + 1
                        );
                        if (
                            dayjs(lastDay).diff(firstDay, "day") >
                            dayjs(date).diff(firstDay, "day")
                        ) {
                            setErrorDepatureDate("warning");
                            openNotificationWithIcon(
                                "warning",
                                "離港日期已修改，多的行程將自動刪除！"
                            );
                        } else {
                            setErrorDepatureDate(null);
                        }
                        setErrorArrivalDate(null);
                        setLastDay(date);
                    }}
                />
            </Form.Item>
            <Form.Item>
                <h3>如多於一日的行程，離港日期不可早於抵港日期</h3>
            </Form.Item>
            <Form.Item
                name="depatureControlPoint"
                label="出境關口"
                {...config}
                style={{
                    display: "inline-block",
                    // width: '50%',
                }}
            >
                <Select
                    placeholder="請選擇出境關口"
                    onSelect={(value) => {
                        console.log("setOtherDepatureControlPoint", value);
                        if (value === "其他") {
                            setOtherDepatureControlPoint(true);
                        } else {
                            setOtherDepatureControlPoint(false);
                        }
                    }}
                >
                    {controlPointList.map((type: string, index: number) => (
                        <Option key={index} value={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {otherDepatureControlPoint && (
                <Form.Item
                    name="otherDepatureControlPoint"
                    label="出境關口如選擇其他，請註明"
                    style={{
                        display: "inline-block",
                    }}
                >
                    <Input />
                </Form.Item>
            )}

            {/* <Form.Item>
            <Button type="primary" htmlType="submit">
                提交
            </Button>
        </Form.Item> */}
        </Form>
    );
}

export default TourInformationForm;
