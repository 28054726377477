import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Button, Space, message, Input, TableColumnType, GetRef } from 'antd';
import { fetchMySubmissions } from '../../api';
import { AuthContext } from '../../AuthContext';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

type InputRef = GetRef<typeof Input>;

interface DataType {
  key: string;
  referenceNumber: string;
  travelAgentName: string;
  travelAgentLicense: string;
  registrationNumber: string;
  travelAgentEmail: string;
  submitDate: string;
}

type DataIndex = keyof DataType;

const MySubmissionsPage = () => {
    const authContext = useContext(AuthContext);
    const { setLoggedIn } = authContext || {};
    const [mySubmissions, setMySubmissions] = React.useState([] as any[]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
  
    const handleSearch = (
      selectedKeys: string[],
      confirm: FilterDropdownProps['confirm'],
      dataIndex: DataIndex,
    ) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      setSearchText('');
    };
  
    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => (searchInput as any).current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    const columns: any[] = [
        {
            title: '參考編號',
            dataIndex: 'referenceNumber',
            key: 'referenceNumber',
            sorter: (a: any, b: any) => a.referenceNumber.localeCompare(b.referenceNumber),
            showSorterTooltip: false,
            ...getColumnSearchProps('referenceNumber'),
        },
        {
            title: '持牌旅行代理商名稱',
            dataIndex: 'travelAgentName',
            key: 'travelAgentName',
            sorter: (a: any, b: any) => a.travelAgentName.localeCompare(b.travelAgentName),
            showSorterTooltip: false,
            ...getColumnSearchProps('travelAgentName'),
        },  
        {
            title: '旅行代理商牌照號碼',
            dataIndex: 'travelAgentLicense',
            key: 'travelAgentLicense',
            sorter: (a: any, b: any) => a.travelAgentLicense.localeCompare(b.travelAgentLicense),
            showSorterTooltip: false,
            ...getColumnSearchProps('travelAgentLicense'),
        },         
        {
            title: '登記貼紙編號',
            dataIndex: 'registrationNumber',
            key: 'registrationNumber',
            sorter: (a: any, b: any) => a.registrationNumber.localeCompare(b.registrationNumber),
            showSorterTooltip: false,
            ...getColumnSearchProps('registrationNumber'),
        },   
        {
            title: '電郵地址',
            dataIndex: 'travelAgentEmail',
            key: 'travelAgentEmail',
            sorter: (a: any, b: any) => a.travelAgentEmail.localeCompare(b.travelAgentEmail),
            showSorterTooltip: false,
            ...getColumnSearchProps('travelAgentEmail'),
        },
        {
            title: '提交時間',
            dataIndex: 'submitDate',
            key: 'submitDate',
            sorter: (a: any, b: any) => a.submitDate.localeCompare(b.submitDate),
            showSorterTooltip: false,
        },
        {
            title: '動作',
            key: 'action',
            render: (text: string, record: any) => (
                <Space size="middle">
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record.id)}>更改</Button>
                </Space>
            ),
        }
    ];

    const handleEdit = (key: string) => {
        const item = mySubmissions.find((item) => item.id === key);
        const encoded = btoa(JSON.stringify({
            referenceNumber: item.referenceNumber,
            registrationNumber: item.registrationNumber,
            travelAgentEmail: item.travelAgentEmail,
        }));
        window.open(`/form/${encoded}`, '_blank');
    };

    async function fetchData() {
        try {
            const response = await fetchMySubmissions();
            if (response.status === 401) {
                if (setLoggedIn) {
                    setLoggedIn(false);
                }
            }
            else if (response.status !== 200) {
                console.log('Failed to fetch submissions');
                return;
            }
            const result = await response.json();
            if (!result.success) {
                console.log('Failed to fetch submissions');
                return;
            }
            setMySubmissions(result.data);
        } catch (err) {
            console.log('Failed to fetch submissions');
            message.error('無法取得提交紀錄');
            setMySubmissions([]);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div style={{ padding: '0.5rem' }}>
            <h2>提交紀錄</h2>
            <Table rowKey="id" columns={columns} dataSource={mySubmissions} />
        </div>
    );
};

export default MySubmissionsPage;