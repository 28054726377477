import React from "react";
import {
    Form,
    Input,
    Button,
    Select,
    Space,
    InputNumber,
    Upload,
    message,
    UploadFile,
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    InboxOutlined,
} from "@ant-design/icons";

const { Option } = Select;

function TourAccommodationForm(options: {
    form: any,
    config: any,
    accommodationTypeList: any,
    roomInfoRequired: any,
    setRoomInfoRequired: any,
    fileList: any,
    setFileList: any,
    jwtToken: any,
    recordId: any,
}) {
    const {
        form,
        config,
        accommodationTypeList,
        roomInfoRequired,
        setRoomInfoRequired,
        fileList,
        setFileList,
        jwtToken,
        recordId,
    } = options;
    const uploadFileProps = {
        name: "file",
        headers: { Authorization: `Bearer ${jwtToken}` },
        action: "/tbms/uploadTourAccommodationFile",
        maxCount: 1,
        data: { id: recordId },
        beforeUpload: (file: any) => {
            console.log("!!!uploadFile", file);
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB < 20) {
                return true;
            } else {
                message.error(
                    file.name + "文件大小超出 20Mb 限制，请修改后重新上传",
                    6
                );
                file.status = "error";
                return false;
            }
        },
    };

    const onRemoveFile = (file: any, roomIndex: string) => {
        console.log("onRemoveFile:", file, roomIndex);
        if (roomIndex === "firstRoom")
            form.setFieldsValue({
                firstRoom: {
                    attachmentLocation: null,
                },
            });
        else {
            let otherRoomInfo = form.getFieldsValue().otherRoomInfo;
            otherRoomInfo[roomIndex]["attachmentLocation"] = null;
            form.setFieldsValue({
                otherRoomInfo: otherRoomInfo,
            });
        }
        return true;
    };

    const uploadFile = (file: any, roomIndex: any) => {
        if (file.status === "uploading") {
            message.info("正在上傳請勿提交");
        }
        if (file.status === "done") {
            console.log(
                "uploadTourAccommodationFile:",
                file.response,
                roomIndex
            );
            let res = file.response;
            if (res.responseCode === "SUCCESS") {
                console.log(
                    "uploadTourAccommodationFile1",
                    form.getFieldsValue()
                );
                if (roomIndex === "firstRoom") {
                    form.setFieldsValue({
                        firstRoom: {
                            attachmentLocation: res.fileId,
                        },
                    });
                    let newFileList = [...fileList];
                    newFileList[0] = null;
                    setFileList(newFileList);
                } else {
                    let otherRoomInfo = form.getFieldsValue().otherRoomInfo;
                    otherRoomInfo[roomIndex]["attachmentLocation"] = res.fileId;
                    form.setFieldsValue({
                        otherRoomInfo: otherRoomInfo,
                    });
                    let newFileList = [...fileList];
                    newFileList[roomIndex + 1] = null;
                    setFileList(newFileList);
                }
                message.success(`${file.name} 成功上傳！`);
            } else {
                file.status = "error";
                message.error(`${res.failReasonMessage}, 請重新上傳文件`, 10);
            }
        } else if (file.status === "error") {
            message.error(`${file.name} 上傳失敗.`);
        }
    };

    const normFile = (e: any) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleKeyDown = (event: any) => {
        if (!/^\d/.test(event.key) && event.key !== "Backspace") {
            event.preventDefault();
        }
    };

    return (
        <Form
            form={form}
            name="tourAccommodation"
            className="tourAccommodation"
            layout="vertical"
            scrollToFirstError
        >
            <Form.Item style={{ width: "100%" }}>
                （香港持牌旅行代理商須就本登記表格的內地入境旅行團，向以下房間供應商預訂或確認下述數目的房間，有關房間足夠讓該團所有參與者住宿；如香港持牌旅行代理商無須安排住宿，或行程不包含住宿，可不填寫此部分。）
            </Form.Item>
            <Form.Item
                name="accommodationProvider"
                label="負責預訂住宿"
                {...config}
            >
                <Select
                    placeholder="請選擇房間供應商"
                    onSelect={(value) => {
                        value === "香港持牌旅行代理商"
                            ? setRoomInfoRequired(true)
                            : setRoomInfoRequired(false);
                    }}
                >
                    {accommodationTypeList.map((type: any, index: any) => (
                        <Option key={index} value={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            {roomInfoRequired && (
                <span>
                    <Space
                        style={{
                            display: "flex",
                            marginBottom: 8,
                            flexWrap: "wrap",
                        }}
                        align="baseline"
                    >
                        <Form.Item
                            name={["firstRoom", "name"]}
                            label="房間供應商名稱"
                            style={{
                                width: "100%",
                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={["firstRoom", "contactName"]}
                            label="房間供應商的聯絡人姓名"
                            style={{
                                width: "100%",
                            }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={["firstRoom", "phoneNumber"]}
                            label="房間供應商的聯絡人電話號碼"
                            style={{
                                width: "100%",
                            }}
                        >
                            <Input
                                // onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                    form.setFieldValue(
                                        "firstRoom",
                                        Object.assign(
                                            form.getFieldsValue().firstRoom,
                                            {
                                                phoneNumber:
                                                    e.target.value.replace(
                                                        /\D/g,
                                                        ""
                                                    ),
                                            }
                                        )
                                    );
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name={["firstRoom", "numberOfRooms"]}
                            label="房間數目"
                            style={{
                                width: "100%",
                            }}
                        >
                            <InputNumber min={1} value={1} />
                        </Form.Item>
                        <Form.Item
                            label="* 請上載有關並只限於本登記表格的內地入境旅行團的住宿證明，供旅監局查核。"
                            {...config}
                            style={{
                                width: "100%",
                            }}
                        >
                            <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload.Dragger
                                    {...uploadFileProps}
                                    onChange={({ file }) =>
                                        uploadFile(file, "firstRoom")
                                    }
                                    onRemove={(file: UploadFile<any>) =>
                                        onRemoveFile(file, "firstRoom")
                                    }
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        上載住宿證明
                                    </p>
                                    <p className="ant-upload-hint">
                                        將文件拖放到這裡
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            name={["firstRoom", "attachmentLocation"]}
                            style={{
                                display: "none",
                            }}
                        ></Form.Item>
                        {fileList[0] && (
                            <Form.Item
                                label="已上傳住宿證明"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <a
                                    target="_blank"
                                    href={fileList[0]?.fileUrl}
                                    download={fileList[0]?.fileName}
                                >
                                    {fileList[0]?.fileName}
                                </a>
                            </Form.Item>
                        )}
                    </Space>

                    <Form.List name="otherRoomInfo">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => {
                                    console.log("list", key, name);
                                    return (
                                        <Space
                                            key={key}
                                            className="otherRoomContainer"
                                            style={{
                                                display: "flex",
                                                marginBottom: 8,
                                                flexWrap: "wrap",
                                            }}
                                            align="baseline"
                                        >
                                            {/* <Form.Item {...restField}
                                        label={`新增房間${name + 1}`} style={{flexBasis:'100%'}}>
                                    </Form.Item> */}
                                            <Form.Item
                                                {...restField}
                                                name={[name, "name"]}
                                                label="房間供應商名稱"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "contactName"]}
                                                label="房間供應商的聯絡人姓名"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "phoneNumber"]}
                                                label="房間供應商的聯絡人電話號碼"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Input
                                                    onKeyDown={handleKeyDown}
                                                    // onChange={(e)=>{
                                                    //     tourAccommodationForm.setFieldsValue({
                                                    //         otherRoomInfo: {}
                                                    //     })
                                                    //     tourAccommodationForm.setFieldValue('otherRoomInfo', Object.assign(tourAccommodationForm.getFieldsValue().otherRoomInfo, {phoneNumber: e.target.value.replace(/\D/g, '')}))
                                                    // }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "numberOfRooms"]}
                                                label="房間數目"
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <InputNumber min={1} />
                                            </Form.Item>

                                            <Form.Item
                                                label="請上載有關並只限於本登記表格的內地入境旅行團的住宿證明，供旅監局查核。"
                                                {...config}
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    valuePropName="fileList"
                                                    getValueFromEvent={normFile}
                                                >
                                                    <Upload.Dragger
                                                        {...uploadFileProps}
                                                        onChange={({ file }) =>
                                                            uploadFile(
                                                                file,
                                                                name
                                                            )
                                                        }
                                                        onRemove={(file: UploadFile<any>) =>
                                                            onRemoveFile(
                                                                file,
                                                                name as any
                                                            )
                                                        }
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">
                                                            上載住宿證明
                                                        </p>
                                                        <p className="ant-upload-hint">
                                                            將文件拖放到這裡
                                                        </p>
                                                    </Upload.Dragger>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        name,
                                                        "attachmentLocation",
                                                    ]}
                                                    style={{
                                                        display: "none",
                                                    }}
                                                ></Form.Item>
                                            </Form.Item>
                                            {fileList[name + 1] && (
                                                <Form.Item
                                                    label="已上傳住宿證明"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            fileList[name + 1]
                                                                ?.fileUrl
                                                        }
                                                        download={
                                                            fileList[name + 1]
                                                                ?.fileName
                                                        }
                                                    >
                                                        {
                                                            fileList[name + 1]
                                                                ?.fileName
                                                        }
                                                    </a>
                                                </Form.Item>
                                            )}
                                            <MinusCircleOutlined
                                                onClick={() => remove(name)}
                                            />
                                        </Space>
                                    );
                                })}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        添加房間
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </span>
            )}

            {/* <Form.Item>
            <Button type="primary" htmlType="submit">
                提交
            </Button>
        </Form.Item> */}
        </Form>
    );
}

export default TourAccommodationForm;
