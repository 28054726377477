import React from "react";
import { Form, Input, Button } from "antd";

function AuthenticateForm(options: { form: any, onAuthentication: any, isFormExpired: any, config: any }) {
    const { form, onAuthentication, isFormExpired, config } = options;
    return (
        <Form
            form={form}
            name="authentication"
            layout="vertical"
            onFinish={onAuthentication}
            scrollToFirstError
        >
            <Form.Item style={{ width: "100%" }}>
                如貴公司希望查詢內地入境旅行團預繳登記費結餘，請到以下連結：
                <a target="_blank" href="https://alt.jotfor.ms/231001717589455">
                    https://alt.jotfor.ms/231001717589455
                </a>
                。請注意：該結餘並非實時數據。
            </Form.Item>
            <Form.Item
                name="registrationNumber"
                label="內地入境旅行團登記貼紙編號"
                {...config}
                extra="請填寫登記貼紙上的編號；每張登記貼紙只可用於登記一個內地入境旅行團"
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="registrationEmail"
                label="香港持牌旅行代理商電郵地址（須與已向旅監局登記，用作登記內地入境旅行團的電郵地址相同）"
                {...config}
            >
                <Input />
            </Form.Item>

            {!isFormExpired && (
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        認證
                    </Button>
                </Form.Item>
            )}
        </Form>
    );
}

export default AuthenticateForm;
